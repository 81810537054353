:root {
    --text-color: #FCFCFC; 
    --bg-color: #023047;
    --bg-color-alt: #219EBC;
    --bg-input: rgba(0, 0, 10, 0.15);

    --table-row: #FCFCFC;
    --status-active: linear-gradient(90deg, rgb(33, 189, 46) -30%, rgba(250,250,250,1) 44%);
    --status-canceled: linear-gradient(90deg, rgb(236, 62, 49) -30%, rgba(250,250,250,1) 44%);
    --status-done: linear-gradient(90deg, #219EBC -30%, rgba(250,250,250,1) 44%);

    --ekora-prussian-blue: #023047;
    --ekora-sky-blue: #BECAE6;
    --ekora-cerulean: #219EBC;
    --ekora-cerulean-alt: #2dbadd;
    --ekora-tangerine: #FB8500;
    --ekora-tangerine-alt: #ffad50;
    --ekora-golden-yellow: #FFB703;
}

@import url('https://fonts.googleapis.com/css2?family=Gantari:ital,wght@0,100..900;1,100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

#root {
  font-family: 'Gantari', sans-serif;
}

.chakra-toast .chakra-alert { 
  font-family: 'Gantari', sans-serif;
}

*::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

*::-webkit-scrollbar-track {
background-color: none
}

*::-webkit-scrollbar-thumb {
background-color: var(--ekora-prussian-blue);
border-radius: 5px;
border: none;
padding: 2px;
background-clip: border-box;
}


.shadowed {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08), 0 2px 5px rgba(0, 0, 0, 0.08);
}

.inner-shadow {
    box-shadow: inset 0 1px 3px rgba(0, 0, 10, 0.0);
}


input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

option {
  color: var(--ekora-prussian-blue);
}